import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppFacadeService } from '../../../services/app-facade.service';
import { Customer } from '../../../models/consumer';
import { finalize, map, Subscription, switchMap, tap, withLatestFrom } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { PorPayEvent } from '../../models/paymentOutput';
import { AppEventService } from '../../../services/app-event.service';
import { OriginActionName } from '@por/shared/ui/cross-app';

@Component({
    selector: 'por-ecom-payment',
    templateUrl: './por-payment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PorPaymentComponent implements OnInit {
    constructor(readonly appFacadeService: AppFacadeService, private readonly appEventService: AppEventService) {}

    @Output() readonly crossAppEvent: EventEmitter<PorPayEvent> = new EventEmitter<PorPayEvent>();

    subscriptions: Subscription[] = [];
    /**
     * Customer information needed, to prefill details on PoRPay page + to verify transaction
     */
    customer$ = this.appFacadeService.getCustomer().pipe(
        tap(() => this.appFacadeService.setLoading(true)),
        withLatestFrom(this.appFacadeService.getCustomerId()),
        switchMap(([customer, customerId]: [Customer, string | null]) => {
            if (isEmpty(customer) && customerId) {
                // Trigger fetch only if the customer is empty and an ID exists
                this.appFacadeService.fetchCustomerById(customerId);
                return this.appFacadeService.getCustomer(); // Re-fetch the customer
            }
            return [customer]; // Return current customer if not empty
        }),
        map((customer: Customer) => {
            if (!isEmpty(customer)) {
                // Only proceed with multiPaymentContinue when customer is successfully loaded
                this.appFacadeService.multiPaymentContinue();
            }
            return customer;
        }),
        finalize(() => {
            this.appFacadeService.setLoading(false);
        })
    );

    ngOnInit(): void {
        this.appFacadeService.getContractDetailFromRouteParams();
        this.subscriptions.push(
            this.appFacadeService.getPaymentCrossAppEvent().subscribe(event => {
                if (event) {
                    this.crossAppEvent.emit(event);
                    this.appEventService.dispatch({
                        action: OriginActionName.PorPayResponse,
                        data: event
                    });
                }
            })
        );
    }
}
