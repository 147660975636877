interface Translations {
    enUSTranslations: { [key: string]: string };
    enGBTranslations: { [key: string]: string };
}
export * from './consumer-login.component/consumer-login.component';
export * from './consumer-login.module';

import * as enUSTranslations from '../assets/en-US.json';
import * as enGBTranslations from '../assets/en-GB.json';
export const ConsumerLoginLanguage: Translations = { enUSTranslations, enGBTranslations };
export * from './consumer-redirection/customer-selection.component';
export * from './validators/app.validators';
export * from './models';
