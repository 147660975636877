import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthUiService } from '../../../services/auth-ui.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { BehaviorSubject, Subscription, take, map } from 'rxjs';
import { GenericError } from '../../../models/error.model';
import { LoginSession } from '../../../models/login-session.model';
import { ErrorService } from '../../../services/error.service';
import { CONSUMER_PORTAL_ADMIN_APP_CONFIG } from '../../../customer-portal-admin-ui.module';
import { ConsumerPortalAdminConfig } from '../../../models/consumer-portal-admin-config';
import { TranslateService } from '@ngx-translate/core';
import { AppEventService } from '../../../services/app-event.service';
import { ComponentsToLoad } from '../../../enums/components-to-load.enum';
import { SiteSettingService } from '../../../services/site-setting.service';
import { DEFAULT_LOCALE } from '../../../constants/default.const';
import { AppFacadeService } from '../../../services/app-facade.service';

@Component({
    selector: 'por-consumer-portal-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerPortalAdminComponent implements OnInit, OnChanges, OnDestroy {
    subscriptions: Subscription[] = [];
    isMobile = false;
    errorMessage$ = this.errorService.error$;
    orgConfig$ = this.configService.orgConfig$.pipe(
        map(config => {
            const inputs: ConsumerPortalAdminConfig = {
                ...(JSON.parse(this.config) as ConsumerPortalAdminConfig),
                styling: {
                    themeColors: {
                        primaryColor: config?.PrimaryColor,
                        secondaryColor: config?.SecondaryColor
                    }
                }
            };
            this.siteSetting.setStylingForAdminSite(inputs);
            return config;
        })
    );
    appliedClass = '';
    @Input() config!: string;
    @Output() readonly errorOutput: EventEmitter<GenericError> = new EventEmitter<GenericError>();
    doScrollToTop = false;

    versionLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        @Inject(CONSUMER_PORTAL_ADMIN_APP_CONFIG) private readonly adminAppConfig: ConsumerPortalAdminConfig,
        private readonly authService: AuthUiService,
        readonly configService: ConfigurationService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly errorService: ErrorService,
        private readonly translateService: TranslateService,
        private readonly appEventService: AppEventService,
        private readonly siteSetting: SiteSettingService,
        readonly appFacadeService: AppFacadeService
    ) {
        this.configService.setAdminConfigurations(undefined, adminAppConfig);

        // Initialize app event service
        this.appEventService.initialize();
        this.authService.initAuth();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.configService.adminInputs$.pipe(take(1)).subscribe(inputs => {
                const logginSession: LoginSession = {
                    ...(inputs.Auth as LoginSession),
                    initialLogin: false,
                    /* eslint-disable @typescript-eslint/naming-convention */
                    /**
                     * Note camelCase:DB Model
                     */
                    OrganizationType: inputs.organizationType,
                    OrganizationId: inputs.organizationId
                };
                this.handleRefreshToken(logginSession);
                if (!this.isSyrnix()) {
                    this.getOrganizationLicense(logginSession?.OrganizationId);
                }
            })
        );

        this.subscriptions.push(
            this.breakpointObserver.observe(['(max-width: 992px)']).subscribe((result: BreakpointState) => {
                this.isMobile = result.matches;
            })
        );

        this.translateService.use(DEFAULT_LOCALE);

        /**
        /**
         * Subscribe to watch http error
         */
        this.subscriptions.push(
            this.errorService.error$.subscribe(result => {
                if (result) {
                    this.emitError(result);
                }
            })
        );
        this.appFacadeService.setActiveTab(ComponentsToLoad.AdminOverview);
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.entries(changes).forEach(([key, value]) => {
            if (key === 'config' && value !== undefined) {
                this.configService.setAdminConfigurations(value.currentValue, this.adminAppConfig);
                this.config = value.currentValue;
                return;
            }
        });
    }

    /**
     * This will subscribe and autorefresh token every 5 minutes
     * Refresh token is stored in localStorage so it can be used anytime to check if user has one active
     */
    handleRefreshToken(authDataObj: LoginSession): void {
        this.subscriptions.push(
            this.authService.activateRefreshAuthTimer(authDataObj).subscribe({
                next: () => {
                    this.appFacadeService.setAdminLoaded(false);
                },
                error: () => {
                    this.appFacadeService.setAdminLoaded(true);
                }
            })
        );
    }

    // Emite error to the parent component using the errorOutput event
    private emitError(error: GenericError): void {
        this.errorOutput.emit(error);
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
        this.appEventService.destroy();
    }

    getOrganizationLicense(organizationId: string | undefined): void {
        if (organizationId !== undefined) {
            this.subscriptions.push(
                this.appFacadeService.versionToggleService.getOrgVersion(organizationId).subscribe(() => {
                    /**
                     * Wait for version to be loaded complete
                     */
                    this.versionLoaded$.next(true);
                })
            );
        }
    }

    isSyrnix(): boolean {
        return this.appFacadeService.featureToggleService.isSyrinx() ? true : false;
    }

    resetScrollFlag(): void {
        this.doScrollToTop = false;
        this.appliedClass = '';
    }

    onClassReceived(cssClass: string) {
        this.appliedClass = cssClass;
    }
}
