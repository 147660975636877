import { CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { CustomerPortalAdminComponent, CPEnvironment, CONSUMER_PORTAL_APP_LANG, CustomerPortalAdminUiModule, CONSUMER_PORTAL_ADMIN_APP_CONFIG } from '@por/consumer-portal/ui-lib';
import { environment } from '../environments/environment';
import { Language } from '@por/shared/core';
import { createCustomElement } from '@angular/elements';

export function getLocaleId() {
    // Checks browser first
    return navigator?.language || 'en-GB';
}

export function getLanguageDropDown() {
    return [
        {
            label: 'UK English',
            value: Language.EN_GB
        },
        {
            label: 'US English',
            value: Language.EN_US
        },
        {
            label: 'Canadian French',
            value: Language.FR_CA
        }
    ];
}

@NgModule({
    imports: [CustomerPortalAdminUiModule],
    providers: [
        { provide: CONSUMER_PORTAL_ADMIN_APP_CONFIG, useValue: environment },
        {
            provide: CPEnvironment,
            useValue: environment
        },
        {
            provide: LOCALE_ID,
            useFactory: getLocaleId
        },
        {
            provide: CONSUMER_PORTAL_APP_LANG,
            useFactory: getLanguageDropDown
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements DoBootstrap {
    constructor(private readonly injector: Injector) {}

    ngDoBootstrap(): void {
        const entryPoint = createCustomElement(CustomerPortalAdminComponent, {
            injector: this.injector
        });
        customElements.define('por-consumer-portal-admin', entryPoint);
    }
}
