import { createReducer, on } from '@ngrx/store';
import { paymentInitialState, PaymentState } from '../state/payment.state';
import {
    crossAppEventEmitted,
    failCreateTransaction,
    failLoadPaymentBoundaries,
    onPaymentFinished,
    setConfigData,
    setDialogEnabled,
    setMultiPaymentBoundaries,
    setPaymentAppInput,
    setTransactionLoaded,
    successVerifyPayment
} from '../actions/payment.actions';
import { continueMultipayment, setPaymentOpen } from '../actions/contract.actions';
import { setPaymentSectionEnabled } from '../actions/active-tab.actions';

export const paymentReducer = createReducer(
    paymentInitialState,
    on(continueMultipayment, (state: PaymentState) => {
        return { ...state, ...paymentInitialState };
    }),
    on(setMultiPaymentBoundaries, (state: PaymentState, { paymentBoundaries }) => {
        return { ...state, paymentBoundaries };
    }),
    on(setConfigData, (state: PaymentState, { config }) => {
        return { ...state, customerId: config?.customerId, organizationId: config?.organizationId };
    }),
    on(setTransactionLoaded, (state: PaymentState, { transactionLoaded }) => {
        return { ...state, transactionLoaded };
    }),
    on(setPaymentAppInput, (state: PaymentState, { paymentAppInput }) => {
        return { ...state, paymentAppInput };
    }),
    on(onPaymentFinished, (state: PaymentState, { paymentAppOutput }) => {
        return { ...state, paymentAppOutput };
    }),
    on(setDialogEnabled, (state: PaymentState, { dialogEnabled }) => {
        return { ...state, dialogEnabled };
    }),
    on(failLoadPaymentBoundaries, (state: PaymentState, { error }) => {
        return { ...state, error };
    }),
    on(failCreateTransaction, (state: PaymentState, { error }) => {
        return { ...state, error };
    }),
    on(setPaymentOpen, (state: PaymentState, { paymentOpen }) => {
        return { ...state, dialogEnabled: paymentOpen };
    }),
    on(successVerifyPayment, (state: PaymentState, { lastPaymentDetail }) => {
        return { ...state, lastPaymentDetail };
    }),
    on(setPaymentSectionEnabled, (state: PaymentState, { enabled }) => {
        return { ...state, ...paymentInitialState };
    }),
    on(crossAppEventEmitted, (state, { event }) => ({
        ...state,
        crossAppEvent: event
    }))
);
