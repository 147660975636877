import { Component, Input, OnDestroy, OnInit, Inject, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import isEmpty from 'lodash-es/isEmpty';
import { BehaviorSubject, Observable, Subscription, takeWhile, tap } from 'rxjs';
import { ConsumerRedirectionConfig } from '../../models';
import { ConsumerPortalConfig, CONSUMER_PORTAL_APP_CONFIG } from '../../models/consumer-portal-config';
import { TranslateService } from '@ngx-translate/core';
import { AuthUiService } from '../../services/auth-ui.service';
import { NotificationService } from '../../services';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { Contact, Customer } from '../../models/consumer';
import { AuthKeys } from '../../enums/local-storage-keys.enum';
import { LoginSession } from '../../models/login-session.model';
import { Auth } from '../../models/auth.model';
import { ConfigurationService } from '../../services/configuration.service';
import { RedirectionUsers } from '../../models/redirection-users';
import { consumerPortalDefaults } from '../../../input-config/consumer-portal-config';
import { GenericError } from '../../models/error.model';
import { ErrorService } from '../../services/error.service';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';
import { AppEventService } from '../../services/app-event.service';
import { OriginActionName } from '@por/shared/ui/cross-app';
import { PendoService } from '../../pendo/services/pendo.service';
import { OrganizationType } from '../../pendo/types/organization-types';
import { VersionToggleService } from '../../services/version-toggle';
import { OverlayContainer } from '@angular/cdk/overlay';
import { PdfService } from '../../services/pdf.service';
import { DEFAULT_LOCALE } from '../../constants/default.const';
import { AppFacadeService } from '../../services/app-facade.service';
import { AppMediatorService } from '../../services/app-mediator.service';
import { Redirection } from '../../types/redirection.type';
import { NetworkService } from '../../services/network.service';
import { PorPayEvent } from '../../payment/models/paymentOutput';
@Component({
    selector: 'por-consumer-portal',
    templateUrl: './customer-portal.component.html',
    styleUrls: ['./customer-portal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerPortalComponent implements OnInit, OnChanges, OnDestroy {
    constructor(
        private readonly auth: AuthUiService,
        private readonly translateService: TranslateService,
        @Inject(CONSUMER_PORTAL_APP_CONFIG) private readonly appConfig: ConsumerPortalConfig,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly featureToggleService: FeatureToggleService,
        readonly configService: ConfigurationService,
        private readonly errorService: ErrorService,
        private readonly appEventService: AppEventService,
        private readonly pendo: PendoService,
        readonly versionToggleService: VersionToggleService,
        private readonly overlayContainer: OverlayContainer,
        private readonly pdfService: PdfService,
        private readonly networkService: NetworkService,
        private readonly notificationService: NotificationService,
        readonly appFacadeService: AppFacadeService,
        private readonly appMediatorService: AppMediatorService,
        private readonly cdr: ChangeDetectorRef
    ) {
        // Initialize app event service
        this.appEventService.initialize();
        // Register event actions
        this.registerEventActions();
        this.auth.initAuth();
        this.configService.setConsumerPortalConfigurations(undefined, { ...consumerPortalDefaults, ...appConfig });
        overlayContainer.getContainerElement().classList.add('customer-portal-wrappper-cp');
    }

    @Input() config!: string;
    cpInput!: ConsumerPortalConfig;

    @Output()
    readonly errorOutput: EventEmitter<GenericError> = new EventEmitter<GenericError>();
    @Output() readonly crossAppPorPayEvent: EventEmitter<PorPayEvent> = new EventEmitter<PorPayEvent>();
    showloader: boolean | undefined = true;
    errorMessage?: string;
    alertMessage = '';
    quickLinkUrl = '';
    subscriptions: Subscription[] = [];
    orgId!: string;
    email: string | undefined;
    redirectionConfig: ConsumerRedirectionConfig | undefined;
    customerId!: string;
    featureService: FeatureToggleService = this.featureToggleService;
    customer$: Observable<Customer> = this.appFacadeService.getCustomer();
    componentRedirection$!: Observable<Redirection | null>;
    selectedLocale!: string;
    reInit = false;

    authUser!: LoginSession;
    contractStatusFilters!: string[];
    isHaveSecurePdf$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    securePdfUrl = '';

    async ngOnInit() {
        this.initializeCpInput();

        this.translateService.use(DEFAULT_LOCALE);

        // Subscribe to watch http error
        this.subscriptions.push(
            this.errorService.error$.subscribe(result => {
                if (result) {
                    this.errorMessage = result.message;
                    this.emitError(result);
                }
            })
        );

        this.subscriptions.push(
            this.translateService.onLangChange.subscribe(() => {
                this.reInit = false;
                setTimeout(() => {
                    this.reInit = true;
                    this.cdr.detectChanges(); // Manually trigger change detection
                });
            })
        );
        this.reInit = true;
        this.document.body.style.backgroundColor = 'var(--por-cp-gray1)';
        this.appFacadeService.getRedirectionSessionData();
        this.subscriptions.push(
            this.appFacadeService.getRedirectionSessionData().subscribe(session => {
                if (session) {
                    this.setLoginSession(session);
                }
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.entries(changes).forEach(([key, value]) => {
            if (key === 'config' && value !== undefined) {
                /**
                 * Set input config which have been provided through when embeded index.html
                 */
                if (this.config && !isEmpty(this.config)) {
                    this.configService.setInputsConfig({
                        ...this.configService.inputs,
                        ...(JSON.parse(this.config) as ConsumerPortalConfig)
                    });
                    this.configService.setConsumerPortalConfigurations(this.config, { ...consumerPortalDefaults, ...this.appConfig });
                }
                this.initializeCpInput();
                this.setRedirectComponentSession();
                return;
            }
        });
    }
    // Note camelCase : DB Model
    // eslint-disable-next-line @typescript-eslint/naming-convention
    setActiveComponent(data: { OrganizationId: string; Type: string }) {
        this.appFacadeService.startRedirection(ComponentsToLoad.Subdomain, data.OrganizationId, this.cpInput);
    }

    loadConsumerPortal($e: boolean) {
        this.appFacadeService.setLoading($e);
    }

    onLoginSuccess(authUser: Auth) {
        // Set InitialLogin to true to set refresh token timer behaviour
        this.setLoginSession({ ...authUser, initialLogin: true });
    }

    private setLoginSession(authUser: LoginSession) {
        /**
         * When refresh has been done using browser
         */
        if (this.cpInput === undefined) {
            this.initializeCpInput();
        }
        if (authUser?.OrganizationId) {
            this.orgId = authUser?.OrganizationId;
        }
        this.email = authUser?.Email;
        this.authUser = authUser;
        this.redirectionConfig = {
            orgId: this.orgId,
            email: this.email,
            apiUrl: this.cpInput?.apiUrl ? this.cpInput?.apiUrl : (this.appConfig.apiUrl as string)
        };

        /**
         * Update CustomerId and orgId from auth session
         */
        this.configService.setConsumerPortalConfigurations(undefined, {
            ...this.cpInput,
            organizationId: authUser?.OrganizationId ?? this.cpInput.organizationId,
            customerId: this.customerId
        });

        // TODO: Not to save all input properties in localstorageservice
        const loggedInUserConfig = {
            ...this.cpInput,
            /* eslint-disable @typescript-eslint/naming-convention */
            /**
             * Note camelCase : DB Model
             */
            Auth: {
                accessToken: authUser.AccessToken ?? authUser.accessToken,
                RefreshToken: authUser.RefreshToken,
                initialLogin: false,
                customerId: authUser.customerId,
                OrganizationType: authUser.OrganizationType,
                OrganizationId: authUser.OrganizationId,
                Email: authUser.Email
            }
        };
        this.featureToggleService.setType(authUser.OrganizationType);
        // Always set InitialLogin to false in the localstorageservice
        this.appMediatorService.localStorageService.persistUser(AuthKeys.User, loggedInUserConfig);
        this.config = JSON.stringify(this.cpInput);
        // Note camelCase : DB Model
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.handleRefreshToken({ ...authUser, OrganizationId: loggedInUserConfig?.organizationId });
        this.appFacadeService.setAuthenticated(!isEmpty(authUser.accessToken));
        this.initPendo();

        // Try open secure pdf if available
        if (authUser.customerId) {
            this.customerId = authUser.customerId;
        }
        this.appFacadeService.processRoute(this.cpInput, this.orgId);
    }

    logout() {
        this.appFacadeService.setRedirected(false);
        this.appFacadeService.setLoading(true);
        this.isHaveSecurePdf$.next(false);
        /**
         * Remove session storage on logout, so it would remove secure pdf url and reset customer id
         */
        this.appMediatorService.localStorageService.removeSessionItem(AuthKeys.User);
        this.customerId = '';

        /**
         * Clear alertMessage, so it wouldn't be visible on consumer login
         */
        this.alertMessage = '';
        this.auth.logout();
    }

    /**
     * This will subscribe and autorefresh token every 5 minutes
     * Refresh token is stored in localStorageService so it can be used anytime to check if user has one active
     */
    handleRefreshToken(authDataObj: LoginSession) {
        this.subscriptions.push(this.auth.activateRefreshAuthTimer(authDataObj).subscribe());
    }

    handleRedirection(contact: RedirectionUsers) {
        if (contact === undefined) {
            this.notificationService.error(this.translateService.instant('unauthorize'));
            this.logout();
            return;
        }
        this.subscriptions.push(
            this.isHaveSecurePdf$.subscribe((check: boolean) => {
                if (check && this.securePdfUrl && contact !== undefined) {
                    this.pdfService.openPdfinNewTab(this.securePdfUrl);
                }
            })
        );
        const user: ConsumerPortalConfig = this.appMediatorService.localStorageService.getCurrentUser as ConsumerPortalConfig;
        this.appMediatorService.localStorageService.persistUser(AuthKeys.User, {
            ...user,
            organizationType: user?.Auth?.OrganizationType,
            // Note camelCase : DB Model
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Auth: {
                ...user?.Auth,
                customerId: contact?.customerId,
                initialLogin: false // Always set InitialLogin to false in the localstorageservice
            }
        });

        this.customerId = contact?.customerId;

        if (!this.versionToggleService.isQuickLinkVersion() && !this.versionToggleService.isAdvanceVersion()) {
            /**
             * Load customer if redirected
             */
            this.appFacadeService.loadCustomerById(this.customerId);
        }
        /**
         * Update CustomerId
         */
        this.configService.setConsumerPortalConfigurations(undefined, {
            ...this.cpInput,
            customerId: this.customerId
        });
        this.appFacadeService.setRedirected(true);
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
        this.appEventService.destroy();
    }

    getCustomerId(customerId: string) {
        if (customerId) {
            this.customerId = customerId;
        }
    }

    openContractsWithFilter(filter: string) {
        this.appFacadeService.setActiveTab(ComponentsToLoad.Contracts);
        this.contractStatusFilters = [filter];
    }

    clearInitFilters() {
        this.contractStatusFilters = [];
    }

    initPendo() {
        this.subscriptions.push(
            this.customer$
                .pipe(
                    tap({
                        next: (customer: Customer) => {
                            if (!isEmpty(customer) || !isEmpty(this.customerId)) {
                                let fullName: string | undefined = customer?.FirstName && customer.LastName ? customer?.FirstName + ' ' + customer.LastName : customer.BillingName;

                                customer?.Contacts?.map((contact: Contact) => {
                                    if (this.authUser?.Email === contact.Contact.Emails?.[0].Email) {
                                        fullName = contact.Contact.Name;
                                    }
                                });

                                if (this.email) {
                                    this.pendo.initializePendo({
                                        orgId: this.orgId,
                                        customerId: this.customerId,
                                        email: this.email,
                                        name: fullName ?? '',
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        RMS: this.featureService.getType() as OrganizationType,
                                        companyName: customer.CompanyName ?? ''
                                    });
                                }
                            }
                        }
                    })
                )
                .pipe(
                    takeWhile(customer => {
                        return isEmpty(customer);
                    })
                )
                .subscribe()
        );
    }

    // Emite error to the parent component using the errorOutput event
    private emitError(error: GenericError) {
        this.errorOutput.emit(error);
    }

    /**
     * This will register all the actions related to this component that are required to be performed on the event
     */
    private registerEventActions() {
        this.appEventService.registerActions({
            [OriginActionName[OriginActionName.LogOff]]: () => {
                return this.logout();
            }
        });
    }

    /**
     * This function will only executes when a configuration is set
     * when consumer portal is embeded in the client's website as web component
     */
    private setRedirectComponentSession() {
        if (!this.configService.inputs?.organizationId?.toString()?.length) {
            this.appFacadeService.setLoading(false);
            if (this.versionToggleService.isConsumerQuickLinkVersion()) {
                this.appFacadeService.startRedirection(ComponentsToLoad.ConsumerPortal, (this.orgId as string) || '', this.cpInput);
            }
            if (this.versionToggleService.isQuickLinkVersion()) {
                this.appFacadeService.startRedirection(ComponentsToLoad.QuickLink, (this.orgId as string) || '', this.cpInput);
                return;
            }
            this.appFacadeService.startRedirection(ComponentsToLoad.Subdomain, (this.orgId as string) || '', this.cpInput);
        } else {
            if ((this.cpInput?.routeTo?.route as ComponentsToLoad) === ComponentsToLoad.Payment) {
                this.appFacadeService.startRedirection(ComponentsToLoad.Payment, (this.cpInput.organizationId as string) || '', this.cpInput);
            } else {
                this.appFacadeService.startRedirection(ComponentsToLoad.ConsumerPortal, this.configService.inputs.organizationId || '', this.cpInput);
            }
        }

        if (this.auth?.user) {
            this.setLoginSession(this.auth.user as LoginSession);
        } else if (this.configService.inputs?.Auth) {
            // Note camelCase : DB Model
            // eslint-disable-next-line @typescript-eslint/naming-convention
            this.setLoginSession({ ...this.configService.inputs?.Auth, OrganizationId: this.configService.inputs?.organizationId, initialLogin: false } as LoginSession);
        }
    }

    returnToLoginFromQuickLink($message: string) {
        this.appFacadeService.setRedirected(false);
        this.appFacadeService.setLoading(true);
        this.alertMessage = $message;
        this.auth.returnToLogin();
    }

    private initializeCpInput() {
        this.subscriptions.push(
            this.configService.cpInputs$.subscribe(inputs => {
                this.cpInput = inputs as unknown as ConsumerPortalConfig;
            })
        );
    }

    returnQuickLinkFromLogin($url: string) {
        this.quickLinkUrl = $url;
    }

    handlePoyPaymentCrossAppEvent(event: PorPayEvent) {
        this.crossAppPorPayEvent.emit(event);
    }
}
