import { Injectable } from '@angular/core';
import { concatMap, map, Observable, of } from 'rxjs';
import { Auth, UserLogin } from '../models/auth.model';
import { ConsumerLoginApiService } from './consumer-login-api.service';
import { RedirectionUsers } from '../models/redirection-users';
import { CreateCustomerRequest, CreateCustomerResponse } from '../models/create-customer.interface';
import { HttpHeaders } from '@angular/common/http';
import { ApiResult } from '@por/shared/core';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    constructor(private readonly apiService: ConsumerLoginApiService) {}

    processUserAccounts(email: string, response: UserLogin, authData: Auth): Observable<{ authData: Auth; userAccounts: RedirectionUsers[] }> {
        return this.getAccountsUsers(email).pipe(
            concatMap((contacts: RedirectionUsers[] | undefined) => {
                const userAccounts = contacts || [];

                if (userAccounts.length === 0) {
                    // Create a new customer
                    return this.createCustomer({
                        name: response?.name,
                        email: email
                    }).pipe(
                        map((createCustomerResponse: CreateCustomerResponse) => ({
                            authData: {
                                ...authData,
                                customerId: createCustomerResponse.customerId,
                                contactId: createCustomerResponse.contactId
                            },
                            userAccounts
                        }))
                    );
                } else if (userAccounts.length > 1) {
                    // Multiple accounts: Handle customer selection

                    return of({ authData, userAccounts });
                } else {
                    // Single account: Assign customer and contact IDs
                    authData = {
                        ...authData,
                        customerId: userAccounts[0]?.customerId,
                        contactId: userAccounts[0]?.contactId
                    };
                    return of({
                        authData,
                        userAccounts
                    });
                }
            })
        );
    }

    /**
     * Get accounts Users from email
     * @param email
     * @returns Observable<RedirectionUsers[]>
     */
    private getAccountsUsers(email: string): Observable<RedirectionUsers[] | undefined> {
        const headers = new HttpHeaders({
            ...this.apiService.requestAuthHeaders()
        });
        return this.apiService
            .send<ApiResult<RedirectionUsers>>({
                verb: 'GET',
                controller: 'customer',
                method: 'find',
                apiRoute: this.apiService.cpApiRoute,
                headers,
                queryParams: {
                    email: email
                }
            })
            .pipe(map((result: ApiResult<RedirectionUsers>) => (result && result.Records ? result.Records : [])));
    }

    /**
     * Create Customer
     * @param request: CreateCustomerRequest
     * @returns  Observable<CreateCustomerResponse>
     */

    private createCustomer(request: CreateCustomerRequest): Observable<CreateCustomerResponse> {
        const headers = new HttpHeaders({
            ...this.apiService.requestAuthHeaders()
        });
        return this.apiService
            .send<ApiResult<CreateCustomerResponse>>({
                verb: 'POST',
                controller: 'customer',
                method: 'create',
                apiRoute: this.apiService.cpApiRoute,
                body: request,
                headers
            })
            .pipe(map((result: ApiResult<CreateCustomerResponse>) => result.Records as unknown as CreateCustomerResponse));
    }
}
