import { Customer } from '../../models/consumer';
import { ContractCP } from '../../models/contract-model';
import { PaymentMinMaxBoundary } from '../../payment/models/payment-data.model';
import { PaymentAppPayOutput, PayOutputEvent, PorPayEvent } from '../../payment/models/paymentOutput';

export interface PaymentState {
    customerId?: string;
    organizationId?: string;
    contracts?: ContractCP[];
    customer?: Customer;
    paymentAppOutput?: PayOutputEvent;
    paymentAppInput?: string;
    transactionLoaded: boolean;
    paymentBoundaries: PaymentMinMaxBoundary[] | null;
    dialogEnabled: boolean;
    error?: string;
    lastPaymentDetail?: PaymentAppPayOutput;
    crossAppEvent: PorPayEvent | null;
}

export const paymentInitialState: PaymentState = {
    paymentBoundaries: null,
    transactionLoaded: false,
    dialogEnabled: false,
    paymentAppInput: undefined,
    error: undefined,
    crossAppEvent: null
};
