import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth } from '../models/auth.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly authData = new BehaviorSubject<Auth | null>(null);
    authData$ = this.authData.asObservable();

    setAuthData(authDataObj: Auth): void {
        this.authData.next(authDataObj);
    }

    getAuthData() {
        return this.authData.value;
    }
}
