<ng-container *ngIf="appFacadeService.getSelectedContractsModels() | async as contract">
    <ng-container *ngIf="contract.length > 0">
        <ng-container *ngIf="customer$ | async | count">
            <por-multipayment></por-multipayment>
        </ng-container>
    </ng-container>
</ng-container>
<por-payment-success-dialog></por-payment-success-dialog>

<div *ngIf="appFacadeService.getContractDetailError() | async as error" class="error-message">
    <p>{{ error }}</p>
</div>
