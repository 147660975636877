import { Injectable } from '@angular/core';
import { Subject, observeOn, asyncScheduler } from 'rxjs';
import { AppEventService } from './app-event.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorType, GenericError } from '../models/error.model';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private readonly error: Subject<GenericError> = new Subject<GenericError>();
    errorDispatch$ = this.error
        .asObservable()
        .pipe(observeOn(asyncScheduler))
        .subscribe(e => {
            this.appEventService.dispatch({
                actionName: e.errorType,
                data: {
                    message: e.message
                }
            });
        });

    constructor(private readonly appEventService: AppEventService, private readonly translateService: TranslateService) {}

    get error$() {
        return this.error.asObservable();
    }

    setError(errorType: ErrorType) {
        const error: GenericError = {
            errorType: errorType,
            message: this.translateService.instant(errorType)
        };

        this.error.next(error);
    }
}
