import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * The cross app event base service
 * @example
 * import { CrossAppEventService } from '@por/shared/ui/cross-app';
 */
@Injectable()
export class CrossAppEventService {
    private eventListener?: EventListener;

    constructor(@Inject(DOCUMENT) private readonly document: Document) {}

    /**
     * Initializes the cross app event service
     * @param ingestEventFn The function that will be called when an event is received
     * @returns void
     * @example
     * initialize(this.ingestEvent.bind(this) as EventListener);
     */
    initialize(ingestEventFn: EventListener) {
        this.eventListener = ingestEventFn;
        this.document.addEventListener('por-cross-app-event', this.eventListener);
    }

    /**
     * Dispatches a cross app event
     */
    dispatch<CrossAppEventDetail>(detail: CrossAppEventDetail) {
        const event: CustomEvent = new CustomEvent<CrossAppEventDetail>('por-cross-app-event', { detail });
        this.document.dispatchEvent(event);
    }

    /**
     * Destroys the cross app event service
     * @returns void
     */
    destroy() {
        this.document.removeEventListener('por-cross-app-event', this.eventListener as EventListener);
    }
}
