<por-demo-loader></por-demo-loader>
<ng-container>
    <div id="apx">
        <div class="demo-wrapper" *ngIf="(view$ | async) !== demoView.Button && (appOpened$ | async) === true">
            <form [formGroup]="stepOneForm" (ngSubmit)="submitConfigurations()" class="step-1" *ngIf="(view$ | async) === demoView.StepOne">
                <apx-form-field [label]="'organizationId' | translate">
                    <input apxInput type="text" [formControl]="$any(stepOneForm.get('organizationId'))" value="" />
                </apx-form-field>

                <div class="organization-select">
                    <label>{{ 'organizationType' | translate }}</label>
                    <select formControlName="organizationType">
                        <option *ngFor="let item of organizations | keyvalue" [value]="item.value">{{ item.value | titlecase }}</option>
                    </select>
                </div>

                <div class="organization-select">
                    <label>{{ 'component' | translate }}</label>
                    <select formControlName="component">
                        <option *ngFor="let item of componentTypeOptions" [value]="item">{{ item }}</option>
                    </select>
                </div>

                <ng-container *ngIf="stepOneForm.value.component === componenType.QUICK_LINK">
                    <apx-form-field [label]="'contractId' | translate">
                        <input apxInput type="text" [formControl]="$any(stepOneForm.get('contractId'))" value="" />
                    </apx-form-field>
                </ng-container>

                <apx-form-field [label]="'xApiKey' | translate">
                    <input apxInput type="text" [formControl]="$any(stepOneForm.get('xApiKey'))" value="" />
                </apx-form-field>

                <apx-form-field [label]="'Email' | translate">
                    <input apxInput type="text" value="" formControlName="email" />
                </apx-form-field>
                <button variant="primary" [disabled]="!stepOneForm.valid" class="form-submitBtn" type="submit" apxButton>{{ 'submit' | translate }}</button>
            </form>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="(view$ | async) === demoView.ConsumerPortal && (appOpened$ | async) === false">
    <por-cp-demo [url]="cpJsUrl" [config]="config"></por-cp-demo>
</ng-container>

<ng-container *ngIf="(view$ | async) === demoView.ConsumerAdmin && (appOpened$ | async) === false">
    <div class="admin-tabs">
        <button (click)="tab.next(0)">Tab 0</button>
        <button (click)="tab1()">Tab 1</button>
        <button (click)="refresh()">{{ 'Refresh' }}</button>
        <button (click)="onLogoutToDemoApp()">{{ 'Go Back to Demo App' }}</button>
    </div>
    <ng-container [ngSwitch]="tab.value">
        <ng-container *ngSwitchCase="0">
            <div class="info-tab0">
                <h1>Click on 'Tab 1' to see the Consumer Portal Admin Component</h1>
                <p>
                    FYI: Switching back to 'Tab 0' destroys the Admin Panel web component.. Clicking on 'Tab 1' will request a new access token and set the input config every time even you are on the
                    'Tab 1'.
                </p>
                <p>Clicking on 'Refresh' will request a new access token and set the input config. Only click it after clicking on 'Tab 1'</p>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="1">
            <ng-container *ngIf="loader.loading$ | async; else notLoading">{{ 'Loading...' }}</ng-container>
            <ng-template #notLoading>
                <por-admin-demo [url]="adminJsUrl" [config]="config"></por-admin-demo>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="(view$ | async) === demoView.QuickLink && (appOpened$ | async) === false">
    <por-cp-demo *ngIf="(quickLinkUrl$ | async) !== ''" [url]="cpJsUrl"></por-cp-demo>
</ng-container>
