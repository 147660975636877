<span apxPrefix apxIcon icon="magnifier" class="search-icon"></span>
<input
    apxInput
    [disabled]="disabled"
    [class]="class"
    [maxlength]="maxlength"
    [placeholder]="placeholder || ('search' | translate)"
    [(ngModel)]="search"
    (focus)="inputFocussed.emit()"
    (keyup)="searchValueChanged.next(search.trim())"
    (keydown.enter)="enterPressed($event)"
    porAddFeature
    [featureName]="featureName" />
<por-base-button featureName="clear-search-btn" class="theme-button cross-search" (onclick)="clearSearch()"><span>X</span></por-base-button>
