import { InjectionToken } from '@angular/core';
import { OrganizationVersionEnum } from '../enums/organization-version.enum';
export interface ConsumerLoginConfig {
    organizationId: string;
    isCustomerSelectionAllowed: boolean;
    logoUrl?: string;
    faviconUrl?: string;
    styling?: {
        themeColors?: {
            primaryColor?: string;
            secondaryColor?: string;
        };
        busyIndicator?: Styling;
        changePassword?: Styling;
        forgotPassword?: Styling;
        signin?: Styling;
        signup?: Styling;
        verify?: Styling;
    };
    loginApiUrl?: string;
    production?: boolean;
    apiUrl?: string;
    uiUrl?: string;
    storeName?: string;
    depotName?: string;
    license?: OrganizationVersionEnum;
    consumerPortalApiUrl?: string;
}
export interface Styling {
    paragraph?: unknown;
    input?: unknown;
    button?: unknown;
    table?: unknown;
    anchor?: unknown;
    header?: unknown;
}

export const CONSUMER_LOGIN_APP_CONFIG = new InjectionToken<ConsumerLoginConfig>('appConfig');
