import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConsumerRedirectionConfig } from '../../models';
import { RedirectionUsers } from '../../models/redirection-users';
import { LoggerService, NotificationService } from '../../services';
import { RedirectionService } from '../../services/redirection.service';
import { TranslateService } from '@ngx-translate/core';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { AuthUiService } from '../../services/auth-ui.service';
import { Searchable } from '../../models/searchable.model';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { RentalMailRequest } from '../../models/rental-mail-request';
import { VersionToggleService } from '../../services/version-toggle';
import { ErrorService } from '../../services/error.service';
import { ErrorType } from '../../models/error.model';
import { AppFacadeService } from '../../services/app-facade.service';
import { AppMediatorService } from '../../services/app-mediator.service';

export enum ConsumerAfterLoggedInActions {
    NotFound = 'Not_Exists',
    RMBMultiple = 'RMB_Multiple',
    RMBSingle = 'RMB_Single',
    Error = 'Error',
    NetworkError = 'NetworkError'
}

@Component({
    selector: 'por-consumer-redirection',
    templateUrl: './consumer-redirection.component.html',
    styleUrls: ['./consumer-redirection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumerRedirectionComponent implements OnInit, OnDestroy {
    constructor(
        private readonly redirection: RedirectionService,
        private readonly logger: LoggerService,
        private readonly consumerPortal: ConsumerPortalApiService,
        private readonly auth: AuthUiService,
        private readonly translateService: TranslateService,
        private readonly appMediatorService: AppMediatorService,
        private readonly featureToggleService: FeatureToggleService,
        private readonly notification: NotificationService,
        private readonly versionToggleService: VersionToggleService,
        private readonly appFacadeService: AppFacadeService,
        private readonly errorService: ErrorService
    ) {}

    loadingRecords = true;
    confirmButtonDisabled = 'true';
    isEmbeded = false;
    message!: string;
    @Output() readonly handleRedirection = new EventEmitter<RedirectionUsers>();
    @Output() readonly getCustomerId = new EventEmitter<string>();
    contacts: RedirectionUsers[] = [];
    @Output() readonly logout = new EventEmitter();
    @Output() readonly loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    selectedContact: RedirectionUsers | undefined;
    consumerAction: ConsumerAfterLoggedInActions | undefined;
    @Input() redirectionConfig: ConsumerRedirectionConfig | undefined;
    subscription: Subscription[] = [];
    featureService: FeatureToggleService = this.featureToggleService;
    errorMessage = '';
    isError = false;
    subscriptions: Subscription[] = [];

    ngOnInit(): void {
        this.errorService.error$.subscribe(res => {
            if (res.errorType === ErrorType.AuthFailed && this.versionToggleService.isConsumerQuickLinkVersion()) {
                this.message = this.translateService.instant('Refresh the page');
            }
        });
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        if (this.auth?.user) {
            this.redirectionConfig = {
                email: this.auth.user?.Email,
                apiUrl: this.auth.user?.apiUrl,
                orgId: this.auth.user?.OrganizationId
            };

            if (this.auth.user?.customerId) {
                this.message = this.translateService.instant('Logging in') + '.......';
            }
        } else {
            this.setRedirectionCustomers();
        }

        // Quicklink doesn't try to find the customer
        // but instead redirects directly
        if (this.versionToggleService.isQuickLinkVersion()) {
            this.loaded.next(false);
            this.consumerAction = ConsumerAfterLoggedInActions.RMBSingle;
            this.handleRedirection.emit({
                contactId: '',
                customerId: this.auth.user?.customerId ?? '',
                customerName: '',
                contractId: this.auth.user?.contractId
            });
            return;
        }

        if (this.redirectionConfig?.email && !this.versionToggleService.isConsumerQuickLinkVersion()) {
            this.subscription.push(
                this.consumerPortal.getUsers(this.redirectionConfig?.email).subscribe({
                    next: (res: Searchable[]) => {
                        this.contacts = res as RedirectionUsers[];
                        this.loaded.next(false);
                    },
                    error: err => {
                        this.logger.logInfo(err);
                        this.contacts = [];
                        this.loadingRecords = false;
                        this.errorMessage = err.message;
                        this.isError = true;
                        this.appFacadeService.setLoading(false);
                        this.sendPorSupportEmail(JSON.stringify(err));
                        /**
                         * If no status have been returned from api consider it has network error
                         */
                        if (err?.status === 0) {
                            this.consumerAction = ConsumerAfterLoggedInActions.NetworkError;
                            return;
                        }
                        this.consumerAction = ConsumerAfterLoggedInActions.Error;
                    },
                    complete: () => {
                        this.isError = false;
                        this.setRedirectionCustomers();
                        this.loadingRecords = false;
                        this.appFacadeService.setLoading(false);
                    }
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscription.map(sub => sub.unsubscribe());
    }

    sendPorSupportEmail(errorMessage: string): void {
        this.subscription.push(
            this.redirection.sendPorSupportEmail(errorMessage).subscribe({
                complete: () => {
                    this.notification.success(this.translateService.instant('PoRRentalEmailSuccess'));
                },
                error: error => {
                    this.isError = true;
                    /**
                     * If no status have been returned from api consider it has network error
                     */
                    if (error?.status === 0) {
                        this.consumerAction = ConsumerAfterLoggedInActions.NetworkError;
                    }
                }
            })
        );
    }

    setRedirectionCustomers(): void {
        let directUser: RedirectionUsers | undefined;
        if (this.contacts.length > 0) {
            if (this.auth.user?.customerId) {
                directUser = this.contacts.find((user: RedirectionUsers) => {
                    return this.auth.user?.customerId?.toString() === user?.customerId?.toString();
                });
                this.consumerAction = ConsumerAfterLoggedInActions.RMBSingle;
                this.handleRedirection.emit(directUser);
                return;
            }

            if (this.contacts?.length > 1) {
                this.consumerAction = ConsumerAfterLoggedInActions.RMBMultiple;
                return;
            }

            if (this.contacts?.length === 1) {
                this.selectedContact = this.contacts?.[0];
                this.consumerAction = ConsumerAfterLoggedInActions.RMBSingle;
                this.handleRedirection.emit(this.selectedContact);
                this.loaded.next(false);
                return;
            }
        }

        this.sendEmailToNotificationEmail();
        this.isError = true;
        this.consumerAction = ConsumerAfterLoggedInActions.NotFound;
    }

    private sendEmailToNotificationEmail(mailRequest: RentalMailRequest = {}): void {
        if (this.redirectionConfig?.email) {
            this.subscription.push(
                this.redirection.sendEmailRMBNotFound(mailRequest).subscribe({
                    complete: () => {
                        this.notification.success(this.translateService.instant('RentalEmailSuccess'));
                    }
                })
            );
        }
    }

    signOut(success: boolean): void {
        this.logout.emit(success);
    }

    openConsumerPortal(): void {
        if (this.selectedContact?.customerId) {
            if (this.contacts?.length === 1) {
                this.getCustomerId.emit(this.selectedContact?.customerId);
            }
            this.handleRedirection.emit(this.selectedContact);
            this.loaded.next(false);
        }
    }

    setUser(user: RedirectionUsers): void {
        this.selectedContact = user;
        this.confirmButtonDisabled = 'false';
    }
}
