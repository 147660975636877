import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RedirectionUsers } from '../models/redirection-users';
@Component({
    selector: 'por-customer-selection',
    templateUrl: './customer-selection.component.html',
    styleUrls: ['./customer-selection.component.scss']
})
export class CustomerRedirectionComponent {
    @Input() contacts: RedirectionUsers[] = [];
    @Input() selectedContact: RedirectionUsers | undefined;
    @Output() readonly contactSelected = new EventEmitter<RedirectionUsers | undefined>();
    @Output() readonly buttonDisabled = new EventEmitter<string>();
    confirmButtonDisabled = 'true';

    setUser(user: RedirectionUsers): void {
        this.confirmButtonDisabled = 'false';
        this.contactSelected.emit(user);
        this.buttonDisabled.emit(this.confirmButtonDisabled);
    }
}
