import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentState } from '../state/payment.state';

export const selectPaymentState = createFeatureSelector<PaymentState>('payment');

export const selectMultipaymentBoundaries = createSelector(selectPaymentState, (state: PaymentState) => state.paymentBoundaries);

export const selectTransactionLoaded = createSelector(selectPaymentState, (state: PaymentState) => state.transactionLoaded);

export const selectPaymentAppInput = createSelector(selectPaymentState, (state: PaymentState) => state.paymentAppInput);

export const selectConfig = createSelector(selectPaymentState, (state: PaymentState) => {
    state.customerId, state.organizationId;
});

export const selectPaymentAppOutput = createSelector(selectPaymentState, (state: PaymentState) => state.paymentAppOutput);

export const selectErrorString = createSelector(selectPaymentState, (state: PaymentState) => state.error);

export const selectDialogEnabled = createSelector(selectPaymentState, (state: PaymentState) => state.dialogEnabled);

export const selectLastPaymentDetail = createSelector(selectPaymentState, (state: PaymentState) => state.lastPaymentDetail);

export const selectCrossAppEvent = createSelector(selectPaymentState, (state: PaymentState) => state.crossAppEvent);
