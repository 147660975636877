<div class="portalnav-wrap por-ac-contracts">
    <div class="wrapper contract-headingSection" *ngIf="!isDetailPageOpened">
        <h2>{{ 'Contract List for' | translate }} {{ customer?.Name }}</h2>
        <span *ngIf="versionToggle.isAdvanceVersion()">{{ 'contractTabInfoText' | translate }}.</span>
    </div>

    <div class="portalnav wrapper" [ngStyle]="{ height: height, width: width }">
        <div class="main-contract-page">
            <div class="contract-wrapper" [ngClass]="{ hide: isDetailPageOpened }">
                <div class="date-range" [ngClass]="{ paymentButtonVisible: isMultipaymentAvailable() }">
                    <por-filters
                        [multiFilters]="filterValues"
                        [initFilters]="initialStatusFilters"
                        [isProcessing]="(appFacadeService.getLoader() | async) || false"
                        [searchPlaceholder]="filterComponentProps.searchPlaceHolder"
                        [dateLabel]="filterComponentProps.dateLabel"
                        (filterOutput)="filter($any($event))"
                        [disableSearch]="!isSearchSupported"
                        (exportCSV)="exportCSV()"
                        [enableExport]="$any(contracts$ | async | count) > 0"
                        [exportButtonEnabled]="selectedContracts.length === 0"
                        [uiUrl]="uiUrl"></por-filters>
                    <div class="contract-payment-button" *ngIf="isMultipaymentAvailable()">
                        <por-base-button
                            #payMultipleContractPanelTrigger="cdkOverlayOrigin"
                            cdkOverlayOrigin
                            (click)="toggleOverlay(true)"
                            [theme]="'primary'"
                            [disabled]="selectedContracts.length === 0"
                            [featureName]="'payment-button'"
                            class="split-button">
                            <por-base-mat-badge [badgeContent]="selectedContracts.length" [isHidden]="selectedContracts.length === 0" badgeColor="warn"></por-base-mat-badge>
                            <span class="actions-image">
                                <svg
                                    class="fill-current"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                    style="enable-background: new 0 0 100 100"
                                    xml:space="preserve">
                                    <g id="credit-card-payment" transform="translate(3 2)">
                                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                                            <path id="Path_11200-2" d="M77.1,36.8c1.2,0,2.3,0.2,3.4,0.5l9.8-9.8l-4.6-4.6L71.9,36.8H77.1z" />
                                        </g>
                                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                                            <path
                                                id="Path_11201-2"
                                                d="M96.9,34.1l-2.5-2.5l-8.9,8.9c2,2.1,3.1,4.9,3.1,7.8v2.3l8.3-8.3C99.1,40,99.1,36.3,96.9,34.1
                                                            C96.9,34.1,96.9,34.1,96.9,34.1z" />
                                        </g>
                                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                                            <path id="Path_11202-2" d="M81.7,18.8L65.2,2.4c-2.2-2.2-5.9-2.2-8.1,0c0,0,0,0,0,0L22.7,36.8h41L81.7,18.8z" />
                                        </g>
                                        <path id="Path_11203" d="M12.6,58.7h8.8v6.5h-8.8V58.7z" />
                                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                                            <path
                                                id="Path_11204-2"
                                                d="M77.1,42.5H6.2c-3.2,0-5.7,2.6-5.7,5.7V93c0,3.2,2.6,5.7,5.7,5.7c0,0,0,0,0,0h70.8
                                                            c3.2,0,5.7-2.6,5.7-5.7l0,0V48.2C82.8,45.1,80.2,42.5,77.1,42.5z M9.8,55h20.2v18H9.8V55z M21,86.3H9.7v-5.7H21V86.3z M38.6,86.3
                                                            H27.2v-5.7h11.4V86.3z M56.1,86.3H44.8v-5.7h11.3V86.3z M73.6,86.3H62.3v-5.7h11.4L73.6,86.3z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span class="line-splitter">{{ 'Make a payment' | translate }}</span>
                        </por-base-button>
                        <por-multi-payment-contract-panel [isOpen]="overlayStates" [trigger]="payMultipleContractPanelTrigger" (closeOverlay)="toggleOverlay(false)"></por-multi-payment-contract-panel>
                    </div>
                </div>

                <div class="content-wrapper" porAddFeature featureName="aggridheader">
                    <div class="title-wrapper">
                        <ng-template #disableMultiplePaymentText>
                            <span class="click-title theme-paragraph">{{ 'Click a contract# link to view contract details' | translate }}</span>
                        </ng-template>
                        <ng-template porFeatureAvailability [featureToken]="['multiplePaymentAbility']" [fallbackTemplate]="disableMultiplePaymentText">
                            <por-contract-instructions></por-contract-instructions>
                        </ng-template>

                        <div class="click-title total-contracts" *ngIf="appFacadeService.getSelectedContractsTotal() | async as total">
                            {{ 'TotalSelectedContracts' | translate }}: {{ total | formatCurrency }}
                        </div>
                    </div>
                    <ag-grid-angular
                        *ngIf="gridReadyObservable$ | async"
                        class="ag-theme-alpine ag-grid"
                        g-grid-angular
                        [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"
                        [rowSelection]="rowSelection"
                        [suppressRowClickSelection]="true"
                        [rowData]="contracts$ | async"
                        [animateRows]="true"
                        [rowHeight]="rowHeight"
                        [headerHeight]="headerHeight"
                        (gridReady)="onGridReady($event)"
                        (cellDoubleClicked)="onCellDoubleClicked($event)"
                        [isFullWidthRow]="isFullWidthRow"
                        [fullWidthCellRenderer]="fullWidthCellRenderer"
                        [context]="context"></ag-grid-angular>
                </div>
                <div class="middle">
                    <por-base-button [featureName]="'load-more'" [class]="'theme-button'" *ngIf="(isRecordAvaible$ | async) === false" (onclick)="loadMore()">
                        {{ 'Load More' | translate }}
                    </por-base-button>
                </div>
            </div>

            <div [ngClass]="{ hide: !isDetailPageOpened }" class="contract-detail">
                <por-contract-detail
                    (closePanel)="isDetailPageOpened = false"
                    [customerId]="customerId"
                    *ngIf="isDetailPageOpened"
                    [contractDetail]="contractDetail"
                    [uiUrl]="inputs?.uiUrl"
                    [customerId]="customerId"
                    (reloadContract)="reloadContract($event)"
                    (reloadContractList)="contractlist.next(true)"></por-contract-detail>
            </div>
        </div>
    </div>
</div>

<por-base-modal [id]="'payment-modal'" [modalWidth]="'modal-lg'" (onclose)="onPaymentClose()">
    <por-multipayment></por-multipayment>
</por-base-modal>

<por-payment-success-dialog></por-payment-success-dialog>
<por-request-sent-successfully-dialog></por-request-sent-successfully-dialog>
