<div class="payment-page">
    <div *ngIf="isPaymentBoundaryAvailable$ | async as isAvailable; else noBoundaryFound">
        <form class="form-wrapper" [formGroup]="form" (ngSubmit)="createTransaction()" *ngIf="versionToggle.isQuickLinkVersion() && !versionToggle.isConsumerQuickLinkVersion()" class="quick-link-pay">
            <ng-container *ngIf="(appFacadeService.getTransactionLoaded() | async) === false">
                <ng-container [ngTemplateOutlet]="paymentRadioBtns" [ngTemplateOutletContext]="{ templateForm: form }"></ng-container>
                <div class="form-group payment-btns">
                    <div class="payment-btn-wrapper">
                        <por-base-button
                            [paymentButtonStyle]="'payment-btn-quicklink'"
                            type="submit"
                            theme="primary"
                            class="btn-success border-btn-green"
                            [disabled]="form.invalid | convertBoolean"
                            featureName="make-a-payment">
                            {{ 'Click to Make a Secure Payment' | translate }}
                        </por-base-button>
                    </div>
                </div>
            </ng-container>
        </form>

        <form class="form-wrapper" [formGroup]="form" (ngSubmit)="createTransaction()" *ngIf="!versionToggle.isQuickLinkVersion() || versionToggle.isConsumerQuickLinkVersion()">
            <div class="form-group">
                <div class="row">
                    <h1 class="theme-heading">{{ 'Online Payment Form' | translate }}</h1>
                </div>
            </div>
            <ng-container *ngIf="(appFacadeService.getTransactionLoaded() | async) === false">
                <div class="form-group totals">
                    <div class="row">
                        <div class="col-6">
                            <p>
                                {{ 'Contract #' | translate }}:
                                <strong>{{ contract.Name }}</strong>
                            </p>
                        </div>
                        <div class="col-6">
                            <p>
                                {{ 'Total Amount' | translate }}:
                                <strong>{{ contract.GrandTotal | formatCurrency }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-group totals">
                    <div class="row">
                        <div class="col-6 empty">
                            <p>&nbsp;</p>
                        </div>
                        <div class="col-6">
                            <p>
                                {{ 'Total Paid' | translate }}:
                                <strong>{{ contractService.getContractTotalPaid(contract) | formatCurrency }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-group totals">
                    <div class="row">
                        <div class="col-6 empty">
                            <p>&nbsp;</p>
                        </div>
                        <div class="col-6">
                            <p>
                                {{ 'Amount Due' | translate }}:
                                <strong>{{ contract.AmountDue | formatCurrency }}</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <ng-container [ngTemplateOutlet]="paymentRadioBtns" [ngTemplateOutletContext]="{ templateForm: form }"></ng-container>
                <div class="form-group payment-btns">
                    <div class="row">
                        <por-base-button
                            [paymentButtonStyle]="'payment-btn'"
                            type="submit"
                            theme="primary"
                            class="btn-success border-btn-green"
                            [disabled]="form.invalid | convertBoolean"
                            featureName="make-a-payment">
                            {{ 'Click to Make a Secure Payment' | translate }}
                        </por-base-button>
                    </div>
                </div>
            </ng-container>
        </form>

        <ng-container *ngIf="appFacadeService.getTransactionLoaded() | async">
            <div *ngIf="appFacadeService.getPaymentAppInput() | async as paymentAppInput">
                <por-payment-app-credit-card
                    id="paymentAppCreditCard"
                    *axLazyElement="porPayURL"
                    [paymentAppInput]="paymentAppInput"
                    (paymentAppOutput)="appFacadeService.onPaymentFinished($any($event))"></por-payment-app-credit-card>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #paymentRadioBtns let-form="templateForm">
    <div class="payment-form" [formGroup]="form">
        <div class="form-group radios">
            <div class="row d-flex" *ngIf="showPaymentRadioButtons(1)">
                <por-base-radio
                    [style]="'baseRadioStyle'"
                    [values]="[{ text: 'PayTotalAmountDue' | translate, value: 1 }]"
                    formControlName="paymentType"
                    [model]="form.value?.paymentType"
                    [required]="true"></por-base-radio>
                <p class="strong-text">{{ contract.AmountDue | formatCurrency }}</p>
            </div>
        </div>
        <div *ngIf="showPaymentRadioButtons(2)">
            <div class="form-group radios">
                <div class="form-group-wrapper row amount-wrapper">
                    <div class="col-2 col-xs-12">
                        <por-base-radio
                            [style]="'baseRadioStyle'"
                            [values]="[{ text: 'Pay Other Amount' | translate, value: 2 }]"
                            formControlName="paymentType"
                            [model]="form.value?.paymentType"
                            [required]="true"></por-base-radio>
                    </div>
                    <div class="col-xs-12">
                        <por-base-input
                            label="{{ 'Enter Amount' | translate }}"
                            class="amount-control"
                            formControlName="amount"
                            type="number"
                            [required]="false"
                            [model]="form.value?.amount"
                            (click)="selectOtherType()"
                            [styleInput]="'payment-input-style'"></por-base-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group radios" *ngIf="showPaymentRadioButtons(3)">
            <div class="row d-flex">
                <por-base-radio
                    [style]="'baseRadioStyle'"
                    [values]="[{ text: payMinimumAmountText, value: 3 }]"
                    formControlName="paymentType"
                    [model]="form.value?.paymentType"
                    [required]="true"></por-base-radio>
                <p class="strong-text">{{ minimumPaymentDue | formatCurrency }}</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noBoundaryFound>
    <p class="text-align-center" *ngIf="isNoPaymentBoundaryFound | async">{{ 'IssueWithPaymentBoundary' | translate }}</p>
</ng-template>
<ng-container *ngIf="appFacadeService.isDialogEnabled() | async">
    <por-app-confirm-dialog [message]="(appFacadeService.getPaymentError() | async) ?? ''" (confirmed)="appFacadeService.closePaymentDialog()"></por-app-confirm-dialog>
</ng-container>
