import { ConsumerLoginConfig } from '../lib/models/consumer-login-config';

export const inputDefaults: ConsumerLoginConfig = {
    organizationId: '',
    logoUrl: '/assets/images/ecom-default-logo.svg',
    faviconUrl: '/assets/images/ecom-default-logo.svg',
    styling: {
        themeColors: {
            primaryColor: '#000000',
            secondaryColor: '#83BE42'
        },
        busyIndicator: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        changePassword: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        forgotPassword: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        signin: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        signup: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        },
        verify: {
            paragraph: {},
            input: {},
            button: {},
            table: {},
            anchor: {},
            header: {}
        }
    },
    loginApiUrl: 'https://dev-consumer-login-api.rentamation.com/v1',
    production: false,
    apiUrl: 'https://dev-consumer-login-api.rentamation.com/v1',
    consumerPortalApiUrl: 'https://dev-consumer-portal-api.rentamation.com/v1',
    isCustomerSelectionAllowed: false,
    storeName: ''
};
