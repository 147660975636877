import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { resetPageNumberContract, fetchContracts, resetContractsLatestToShow } from '../store/actions/contract.actions';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import { Searchable } from '../models/searchable.model';
import { ContractCP, ContractDetail } from '../models/contract-model';
import isEmpty from 'lodash-es/isEmpty';
import { FilterOutput } from '../shared/models/filters.model';
import { ProductDetail, ProductDetailSearchable } from '../models/product-detail';
import { CloneContract } from '../models/clone-contract.model';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { FormatCurrencyPipe } from '../pipes/format-currency.pipe';
import { TranslateService } from '@ngx-translate/core';

type ContractDetailSearchable = Searchable & ContractDetail;
@Injectable({
    providedIn: 'root'
})
export class ContractService {
    constructor(
        private readonly store: Store,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly formatCurrency: FormatCurrencyPipe,
        private readonly translateService: TranslateService
    ) {}
    loadContracts({ customerId, status = [], startDate, endDate, search }: { customerId: string; status?: string[] | undefined; startDate?: string; endDate?: string; search?: string }) {
        this.store.dispatch(fetchContracts({ customerId, status, startDate, endDate, search }));
    }

    getContractsDetails(customerId: string, contractId: string, columns?: string[]) {
        const headers: { [key: string]: string } = {};
        const url = `customer/${customerId}/contracts/${contractId}/detail`;
        if (columns && columns.length > 0) {
            headers['x-columns'] = JSON.stringify(columns);
            return this.consumerPortalApi.get<ContractDetailSearchable>(url, { headers: headers });
        } else {
            return this.consumerPortalApi.get<ContractDetailSearchable>(url, { headers: headers }).pipe(
                tap({
                    next: (details: ContractDetail[]) => {
                        return details.map((detail: ContractDetail) => {
                            const lineItems = detail.LineItems.filter(lineItem => lineItem.Classification === 'Rental' || lineItem.Classification === 'Sale' || lineItem.Classification === null);
                            detail.LineItems = lineItems;
                        });
                    }
                })
            );
        }
    }

    getProductDetails(customerId: string, productId: string, stockId: string, columns?: string[]) {
        const url = `product/${customerId}/?productId=${productId}&stockId=${stockId}`;
        const headers: { [key: string]: string } = {};
        if (columns && columns.length > 0) {
            headers['x-columns'] = JSON.stringify(columns);
        }
        return this.consumerPortalApi.get<ProductDetailSearchable>(url, { headers: headers }).pipe(
            tap({
                next: (productDetail: ProductDetail[]) => {
                    return productDetail;
                }
            })
        );
    }

    filter($e: FilterOutput, customerId: string) {
        const filters =
            $e.multifilters?.map((value: string) => {
                return value;
            }) || [];

        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? $e.dates.startDate : undefined;

        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? $e.dates.endDate : undefined;
        this.store.dispatch(resetPageNumberContract());
        this.store.dispatch(resetContractsLatestToShow());
        this.loadContracts({
            customerId,
            status: filters,
            startDate,
            endDate,
            search: $e.search
        });
    }

    cloneContract(cloneRequest: CloneContract) {
        const params = {
            controller: `customer/${cloneRequest.customerId}/clone-contract`,
            method: 'POST',
            body: cloneRequest
        };
        return this.consumerPortalApi.post({ ...params }).pipe(
            tap({
                next: () => {
                    return of(true);
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error: (err: HttpErrorResponse) => {
                    return of(false);
                }
            })
        );
    }

    getAmountDueText(amountDue: number | string, paymentPending: boolean | undefined = false): string {
        if (paymentPending) {
            return this.translateService.instant('Pending');
        } else {
            return this.formatCurrency.transform(String(amountDue));
        }
    }

    getContractTotalPaid(contract: ContractDetail | undefined): number {
        let totalPaid = 0;
        if (contract) {
            const grandTotal: number | undefined = Number(contract.GrandTotal);
            const amountDue: number | undefined = contract.AmountDue;
            totalPaid = grandTotal - amountDue;
        }
        return totalPaid;
    }

    convertContractDetailToContractCP(contractDetail: ContractDetail): ContractCP {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            contractId: contractDetail.Id,
            contractName: contractDetail.Name,
            status: contractDetail.Status ?? contractDetail.ContractStatus,
            openDate: contractDetail.StartDateTime,
            closeDate: contractDetail.EndDateTime,
            purchaseOrder: '',
            jobNumber: '',
            info: '',
            GrandTotal: contractDetail.GrandTotal ?? '0',
            AmountDue: String(contractDetail.AmountDue) ?? '0',
            depotId: contractDetail?.DepotId ?? '0',
            LineItems: contractDetail?.LineItems || [],
            customerId: contractDetail?.CustomerId ?? '0',
            itemsOut: false,
            paid: '',
            CurrencyCode: contractDetail?.CurrencyCode
        };
    }
}
