import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, catchError, debounceTime, map, switchMap, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateUtility } from '../../../date.utility';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { ContractDetail, Payment } from '../../../models/contract-model';
import { PaymentProps } from '../../models/payment-props.enum';
import { paymentFormValidator } from '../../../validators/payment-form.validator';
import { select, Store } from '@ngrx/store';
import { getCustomer } from '../../../store/selector/customer.selectors';
import { Customer } from '../../../models/consumer';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { CPEnvironment } from '../../../models';
import { ConsumerPortalEnviromentConfig } from '../../../models/environment-consumer-portal';
import { PaymentMinMaxBoundary } from '../../models/payment-data.model';
import { VersionToggleService } from '../../../services/version-toggle';
import { AppFacadeService } from '../../../services/app-facade.service';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { ContractService } from '../../../services/contract.service';
import { PayMinimumAmount } from '../../../models/pay-minimum-amount.model';

@Component({
    selector: 'por-payment-page',
    templateUrl: './por-payment-page.component.html',
    styleUrls: ['./por-payment-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PorPaymentPageComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        public readonly appFacadeService: AppFacadeService,
        private readonly translateService: TranslateService,
        private readonly appMediatorService: AppMediatorService,
        private readonly store: Store,
        private readonly featureToggleService: FeatureToggleService,
        public versionToggle: VersionToggleService,
        readonly contractService: ContractService,
        @Inject(LOCALE_ID) locale: string,
        @Inject(CPEnvironment) private readonly appConfig: ConsumerPortalEnviromentConfig
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    customer!: Customer;
    @Input() enableBackButton = false;
    @Input() customerEmail!: string | undefined;
    @Input() customerId = '';
    @Input() contract!: ContractDetail;
    @Input() organizationId!: string;
    isTransactionGoing = false;
    @Output() readonly appOutput = new EventEmitter();
    @Output() readonly paymentSuccess: EventEmitter<Payment> = new EventEmitter<Payment>();
    dateFormat!: string;
    featureService = this.featureToggleService;
    porPayURL = this.appConfig.porPayScriptURL;

    private readonly subscriptions: Subscription[] = [];
    apiUrl = this.appConfig.gapiApiBaseUrl;
    paymentAppInput$: BehaviorSubject<string> = new BehaviorSubject('');
    apiKey = '';
    @Output() readonly closePanel = new EventEmitter<boolean>();

    paymentBoundaryMinMax!: PaymentMinMaxBoundary[];
    minimumPaymentDue = 0;
    isDialogEnabled = false;
    dialogMessage = '';
    isNoPaymentBoundaryFound = new BehaviorSubject<boolean>(false);
    payMinimumAmountText = '';

    form: FormGroup = new FormGroup(
        {
            paymentType: new FormControl(PaymentProps.TYPE_FULL_AMOUNT_DUE, {
                validators: [Validators.required]
            }),
            amount: new FormControl('')
        },
        {
            validators: [paymentFormValidator()]
        }
    );
    private readonly isPaymentBoundaryAvailable = new BehaviorSubject<boolean>(false);
    isPaymentBoundaryAvailable$ = this.isPaymentBoundaryAvailable.pipe(
        tap(() => {
            this.appFacadeService.setLoading(true);
        }),
        switchMap(() => {
            return this.appMediatorService.porPayService.getMinMaxFromPaymentBoundary(this.customerId, this.contract.Id).pipe(
                map((res: PaymentMinMaxBoundary[]) => {
                    this.appFacadeService.setLoading(false);
                    if (res.length === 0) {
                        this.isNoPaymentBoundaryFound.next(true);
                        return false;
                    }
                    this.paymentBoundaryMinMax = res;
                    return true;
                }),
                catchError(e => {
                    this.appFacadeService.setLoading(false);
                    throw new Error(this.translateService.instant('somethingWentWrong', e));
                })
            );
        })
    );

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
        this.subscriptions.push(
            this.store.pipe(select(getCustomer)).subscribe((customer: Customer) => {
                this.customer = customer;
            })
        );
        this.subscriptions.push(
            this.appFacadeService.getConfigPayMinimumAmount().subscribe((value: PayMinimumAmount) => {
                this.payMinimumAmountText = value.translate ? this.translateService.instant(value.text) : value.text;
            })
        );
        this.appFacadeService.setSelectedContracts([this.contract.Id]);
    }

    ngAfterViewInit(): void {
        /**
         * IF min is null/0 then the min Amount is 1.
         */
        this.form.controls['amount'].setValidators([Validators.min(this.minimumPaymentDue ?? 1), Validators.max(this.contract.AmountDue)]);
        this.form.controls['amount'].updateValueAndValidity();
        this.subscriptions.push(
            this.form.valueChanges.pipe(debounceTime(100)).subscribe(val => {
                if (val?.paymentType === PaymentProps.TYPE_FULL_AMOUNT_DUE) {
                    this.form.controls?.['amount'].setValue('');
                }
            })
        );
    }

    createTransaction(): void {
        if (this.form.valid && this.customerId) {
            this.appFacadeService.createTransaction();
        }
    }

    selectOtherType(): void {
        this.form.get('paymentType')?.setValue(PaymentProps.TYPE_OTHER_AMOUNT);
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    /**
     * Show Payment Radio Buttons
     * @param {PaymentProps} button
     * @returns
     */
    showPaymentRadioButtons(button: PaymentProps): boolean {
        const { min, max } = this.filterPaymentBoundary();
        switch (button) {
            case PaymentProps.TYPE_FULL_AMOUNT_DUE:
                return this.featureService.isAvailable('payFullAmount') ? true : false;
            case PaymentProps.TYPE_OTHER_AMOUNT:
                // if both min and max are same, We want to hide Pay Other Amount button
                if (min > 0 && max > 0 && min === max) {
                    return false;
                }
                return (min < this.contract.AmountDue && max > this.contract.AmountDue) || max <= this.contract.AmountDue ? true : false;
            case PaymentProps.TYPE_MIN_AMOUNT_DUE:
                if (min === 0 || min === 1 || min === max) {
                    return false;
                }
                return (min < this.contract.AmountDue && max > this.contract.AmountDue) || max <= this.contract.AmountDue ? true : false;
            default:
                return false;
        }
    }

    filterPaymentBoundary(): { min: number; max: number } {
        /**
         * Initilize min and max with default non zero value
         */
        let min = this.paymentBoundaryMinMax[0].minAmount;
        let max = this.paymentBoundaryMinMax[0].maxAmount;
        this.paymentBoundaryMinMax.map((boundary: PaymentMinMaxBoundary) => {
            if (boundary.minAmount < min) {
                min = boundary.minAmount;
            }
            if (boundary.maxAmount > max) {
                max = boundary.maxAmount;
            }
        });
        /**
         * IF min is 0 then the min Amount is 1.
         */
        this.minimumPaymentDue = min === 0 ? 1 : min;
        return { min: min, max: max };
    }
}
