// redirection.reducer.ts
import { createReducer, on } from '@ngrx/store';
import {
    startRedirection,
    redirectionSuccess,
    redirectionFailure,
    setRedirectionLoginSession,
    redirectToSubdomain,
    redirectToConsumerPortal,
    redirectConfigSet,
    redirectToPayment
} from '../actions/redirection.action';
import { redirectionInitialState } from '../state/redirection.state';
import { ComponentsToLoad } from '../../enums/components-to-load.enum';

export const redirectionReducer = createReducer(
    redirectionInitialState,
    on(startRedirection, state => ({
        ...state,
        error: null
    })),
    on(redirectionSuccess, (state, { redirection }) => ({
        ...state,
        redirection,
        error: null
    })),
    on(redirectionFailure, (state, { error }) => ({
        ...state,
        redirection: { ...state.redirection, toLoad: ComponentsToLoad.RoutingError, orgId: state.redirection?.orgId ?? '' },
        error
    })),
    on(redirectToSubdomain, state => ({
        ...state,
        redirection: { ...state.redirection, toLoad: ComponentsToLoad.Subdomain, orgId: state.redirection?.orgId ?? '' },
        error: null
    })),
    on(redirectToConsumerPortal, state => ({
        ...state,
        redirection: { ...state.redirection, toLoad: ComponentsToLoad.ConsumerPortal, orgId: state.redirection?.orgId ?? '' },
        error: null
    })),
    on(setRedirectionLoginSession, (state, { session }) => ({
        ...state,
        session
    })),
    on(redirectConfigSet, (state, { isConfigSet }) => ({
        ...state,
        isConfigSet
    })),
    on(redirectToPayment, state => ({
        ...state,
        redirection: { ...state.redirection, toLoad: ComponentsToLoad.Payment, orgId: state.redirection?.orgId ?? '' },
        error: null
    }))
);
