/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: DB model
export interface User {
    Email: string;
    StoreName?: string;
    Password?: string;
    code?: string;
    Name?: string;
    CompanyName?: string;
    PhoneNumber?: string;
}
