import { Observable, catchError, map, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { PaymentData, PaymentMinMaxBoundary, PaymentResponse } from '../models/payment-data.model';
import { Processors } from '../models/processors.interface';
import { CPEnvironment } from '../../models';
import { ConsumerPortalEnviromentConfig } from '../../models/environment-consumer-portal';
import { ContractCP, Payment } from '../../models/contract-model';
import { PaymentAppPayInput, PaymentCustomerDataInput } from '../models/paymentInput';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import { getCustomerPaymentData } from '../../store/selector/customer.selectors';
import { Customer } from '../../models/consumer';
import { PaymentAppPayOutput } from '../models/paymentOutput';
import { PaymentMethodEnums } from '../../enums/payment-method.enum';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_CURRENCY } from '../../constants/default.const';

@Injectable({
    providedIn: 'root'
})
export class PorPayService {
    constructor(
        private readonly store: Store<AppState>,
        private readonly logger: LoggerService,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly translateService: TranslateService,
        @Inject(CPEnvironment) private readonly appConfig: ConsumerPortalEnviromentConfig
    ) {}

    loadScriptForPOR() {
        this.logger.logWarning('Will Load Script Here');
    }

    createPayment(
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase : DB Model
         */
        CustomerGuid: string,
        CurrencyCode: string,
        Amount_Requested: number,
        OrganizationId: string,
        RemoteDepotId: string,
        contractIds: string[],
        amountBeingPaidBeforeFee: number
    ): Observable<PaymentResponse> {
        return this.consumerPortalApi
            .post<PaymentResponse>({
                controller: `payment/${CustomerGuid}/create-transaction`,
                method: 'POST',
                body: {
                    CustomerGuid: String(CustomerGuid),
                    Amount_Requested: Number(Amount_Requested),
                    CurrencyCode: String(CurrencyCode),
                    RemoteDepotId: String(RemoteDepotId),
                    contractIds,
                    amountBeingPaidBeforeFee
                },
                headers: {
                    'x-organization-id': OrganizationId
                }
            })
            .pipe(
                tap({
                    error: (err: HttpErrorResponse) => {
                        this.logger.logWarning(err?.message);
                        this.logger.alertDevError(err);
                        return err;
                    },
                    next: (response: PaymentResponse) => {
                        this.logger.logInfo(response);
                        return response?.data;
                    }
                })
            );
    }

    verifyPayment(customerId: string, paymentData: PaymentData): Observable<Payment> {
        return this.consumerPortalApi
            .post<Payment>({
                controller: `payment/${customerId}/verify-transaction`,
                method: 'POST',
                body: paymentData,
                headers: {
                    'x-organization-id': paymentData.organizationId ?? ''
                },
                camelCase: false
            })
            .pipe(
                tap({
                    error: (err: HttpErrorResponse) => {
                        return err;
                    },
                    next: (response: Payment) => {
                        return response;
                    }
                })
            );
    }

    getMinMaxFromPaymentBoundary(customerId: string, contractId: string): Observable<PaymentMinMaxBoundary[]> {
        return this.consumerPortalApi.get<PaymentMinMaxBoundary>(`payment/${customerId}/get-boundary/${contractId}`).pipe(
            tap({
                next: (data: PaymentMinMaxBoundary[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    return error;
                }
            })
        );
    }

    getPaymentBoundaryMultiContract(customerId: string, contractIds: string[], amount: number) {
        return this.consumerPortalApi
            .post<PaymentMinMaxBoundary[]>({
                controller: `payment/${customerId}/get-multi-boundary`,
                method: 'POST',
                body: {
                    contractIds,
                    amount
                }
            })
            .pipe(
                tap({
                    next: (data: PaymentMinMaxBoundary[]) => {
                        return data;
                    },
                    error: (error: HttpErrorResponse) => {
                        return error;
                    }
                })
            );
    }

    /**
     * Get the payment processors
     * @param {string} customerId
     * @returns Processors[]
     */
    getProcessors(customerId: string): Observable<Processors[]> {
        return this.consumerPortalApi.get<Processors>(`payment/${customerId}/processors`).pipe(
            tap({
                next: (data: Processors[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    return error;
                }
            })
        );
    }

    /**
     * Hide Make Payment button based on payment processors, no processors are setup, hide and if consumer facing
     * is not available then also hide
     * @param {string} customerId
     * @returns {Observable<boolean | Processors[]>} false or Processors[]
     */
    isPaymentProcessorAvailable(customerId: string): Observable<boolean | Processors[]> {
        return this.getProcessors(customerId).pipe(
            map((processors: Processors[]) => {
                if (processors.length === 0) {
                    return false;
                }
                let availableProcessors: Processors[] = processors.filter(processor => processor.RemoteDepotId !== null);
                if (this.appConfig?.envName !== 'stage') {
                    availableProcessors = availableProcessors.filter(processor => processor?.IsCustomerFacing);
                }

                if (availableProcessors.length === 0) {
                    return false;
                }

                return availableProcessors;
            }),
            catchError((error: HttpErrorResponse) => {
                this.logger.logError(error, '', true);
                return of(false);
            })
        );
    }

    getPaymentConfiguration(apiKey: string, transactionId: string): Observable<PaymentAppPayInput> {
        return this.getCustomerPaymentData().pipe(
            map(customerData => {
                const configuration: PaymentAppPayInput = {
                    /* eslint-disable @typescript-eslint/naming-convention */
                    /**
                     * Note camelCase : DB Model
                     */
                    ApiKey: apiKey,
                    TransactionId: transactionId,
                    ApiUrl: this.appConfig.gapiApiBaseUrl,
                    CustomerData: customerData
                };

                return configuration;
            })
        );
    }

    getPaymentData(customer: Customer | undefined, organizationId: string, paymentOutput: PaymentAppPayOutput, contracts: ContractCP[]): PaymentData {
        const phone: string = customer?.Phones?.[0]?.Number ? customer?.Phones?.[0]?.Number : '0000000000';
        const email: string = paymentOutput?.Customer?.Email ? paymentOutput?.Customer?.Email : 'default@por.com';

        let address = '';
        if (customer?.Addresses && customer.Addresses.length > 0) {
            address = customer.Addresses?.[0]?.Line1 ? customer.Addresses?.[0]?.Line1 : '';
        }

        return {
            organizationId: organizationId,
            amount: paymentOutput?.TransactionData?.Amount_Processed ? this.convertCurrency(paymentOutput?.TransactionData?.Amount_Processed) : 0,
            contractIds: contracts.map(c => c.contractId),
            referenceNumber: paymentOutput?.TransactionId ? paymentOutput?.TransactionId : '',
            currency: paymentOutput?.TransactionData?.CurrencyCode ? paymentOutput?.TransactionData?.CurrencyCode : DEFAULT_CURRENCY, // default to USD
            depotId: contracts[0]?.depotId ? contracts[0]?.depotId : '0',
            address,
            companyName: customer?.CompanyName ? customer?.CompanyName : 'POR',
            email,
            firstName: customer?.FirstName ? customer?.FirstName : customer?.Name,
            lastName: customer?.LastName ? customer?.LastName : customer?.Name,
            phone
        };
    }

    formatCurrencyForPorPay(input: string): string {
        const decimalIndex: number = input.indexOf('.');

        if (decimalIndex === -1) {
            return input + '00';
        }

        const wholeNumberPart: string = input.slice(0, decimalIndex);
        const fractionalPart: string = input.slice(decimalIndex + 1);
        const paddedFractionalPart: string = fractionalPart.padEnd(2, '0');

        return wholeNumberPart + paddedFractionalPart.slice(0, 2);
    }

    /**
     * Convert smallest denomination of number to actual value, eg. 100(cents) to $1
     * @param {number} amount
     * @returns {number}
     */
    convertCurrency(amount: number): number {
        const dollars = amount / 100;

        // Format the result to two decimal places
        return parseFloat(dollars.toFixed(2));
    }

    getCustomerPaymentData(): Observable<PaymentCustomerDataInput> {
        return this.store.pipe(select(getCustomerPaymentData));
    }

    getPaymentMethod(paymentMethod: PaymentMethodEnums | undefined): string {
        switch (paymentMethod) {
            case PaymentMethodEnums.CreditCard:
                return this.translateService.instant('PaymentMethod.CreditCard');
            case PaymentMethodEnums.DebitCard:
                return this.translateService.instant('PaymentMethod.DebitCard');
            case PaymentMethodEnums.GiftCard:
                return this.translateService.instant('PaymentMethod.GiftCard');
            case PaymentMethodEnums.ACH:
                return this.translateService.instant('PaymentMethod.ACH');
            case PaymentMethodEnums.Crypto:
                return this.translateService.instant('PaymentMethod.Crypto');
            default:
                return '';
        }
    }
}
