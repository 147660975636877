<div class="notifier-wrapper">
    <div class="rms-found-many">
        <div class="wrap">
            <ul>
                <ng-container *ngFor="let contact of contacts">
                    <li
                        (click)="setUser(contact)"
                        [ngClass]="{
                            active: contact?.customerId === selectedContact?.customerId
                        }"
                        porAddFeature
                        featureName="consumer-select-button-{{ contact?.customerId }}">
                        <p>
                            {{ contact?.customerName | uppercase }}
                        </p>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
